import React from "react";
import Layout from "../components/layout";
import Wrapper from "../components/wrapper/Wrapper";
import Section from "../components/section/Section";
import SEO from "../components/seo";
import { aboutMessage as AboutMessage } from "../data/data";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const About = () => {
  const style = {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    objectFit: "cover",
    width: "300px !important",
    height: "300px !important",
    borderRadius: "50%",
  };
  return (
    <Layout>
      <SEO title="講師について|ヨガスタジオ笠置" />
      <Wrapper>
        <Section className="active">
          <article className="article-container">
            <h1 className="title">講師について</h1>
            <ArticleContainer>
              <StaticImage
                layout="constrained"
                src="../images/profile.jpg"
                alt="講師の写真"
                // imgClassName="profile-picture"
                // className="profile-wrapper"
                // width="300px"
                // height="300px"
                // aspectRatio={1}
                style={{ width: "300px", height: "300px", borderRadius: "50%" }}
                imgStyle={style}
              />
              <AboutMessage />
            </ArticleContainer>
          </article>
        </Section>
      </Wrapper>
    </Layout>
  );
};

export default About;
